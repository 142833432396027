import { Card, Grid } from '@mui/material';
import { GET_CUSTOM_PACKAGE } from 'src/apiService/apiDeclaration';
import CustomPackageForm from 'src/components/form/customPackageForm';
import CustomLoader from 'src/components/loader/loader';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import useApiGetter from 'src/hooks/useApiGetter';

const CustomPackagePage = () => {
  const { data } = useApiGetter(GET_CUSTOM_PACKAGE);

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Custom Package'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Custom Package'} />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>{!data ? <CustomLoader /> : <CustomPackageForm data={data} />}</Card>
      </Grid>
    </Grid>
  );
};

export default CustomPackagePage;
