import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ICONS } from 'src/assets/library';
import { updateUserSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelectField from '../formFields/selectField';
import { UPDATE_USER } from 'src/apiService/apiDeclaration';
import CustomTextField from 'src/components/formFields/textField';
import LoaderButton from 'src/components/loaderButton/loaderButton';
import { formatDate, modelArray, showErrorTost, showSuccessTost } from 'src/constants';

const UpdateUserForm = ({ data, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    email: data.email,
    name: data.name,
    package: {
      ...data.package,
      expireOn: data?.package?.expireOn ? formatDate(data.package.expireOn) : '',
      ...(data?.package?.id?._id ? { id: data.package.id?._id } : {}),
    },
    packageFeatures: {
      ...data.packageFeatures,
      model: data.packageFeatures.model,
      words: data.packageFeatures.words,
      credits: data.packageFeatures?.credits || 0,
    },
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(updateUserSchema),
    defaultValues,
  });

  const onSubmit = async (body) => {
    setLoading(true);
    try {
      await UPDATE_USER(data._id, body);
      showSuccessTost('User updated successfully.');
      onSuccess();
    } catch (error) {
      showErrorTost('Error in updating user.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            icon={ICONS.user}
            controller={{
              name: 'name',
              control: control,
              errors: errors.name,
            }}
            disabled={data.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            placeholder={'name@domain.com'}
            type="email"
            icon={ICONS.email}
            controller={{
              name: 'email',
              control: control,
              errors: errors.email,
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={'Words'}
            required={false}
            type="number"
            icon={ICONS.words}
            controller={{
              name: 'packageFeatures.words',
              control: control,
              errors: errors?.packageFeatures?.words,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={'Credits'}
            required={false}
            type="number"
            icon={ICONS.words}
            controller={{
              name: 'packageFeatures.credits',
              control: control,
              errors: errors?.packageFeatures?.credits,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelectField
            label={'Model'}
            optionsArray={modelArray}
            controller={{
              name: 'packageFeatures.model',
              control: control,
              errors: errors?.packageFeatures?.model,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={'Expiration Date'}
            type="date"
            icon={ICONS.date}
            controller={{
              name: 'package.expireOn',
              control: control,
              errors: errors?.package?.expireOn,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <LoaderButton disabled={!isValid || !isDirty} loading={loading} buttonText={'Update'} type="submit" />
        </Grid>
        <Grid item xs={12} md={3}>
          <LoaderButton onClick={() => reset(defaultValues)} buttonText={'reset'} color="warning" />
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateUserForm;
