import React, { useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { ADD_USER } from 'src/apiService/apiDeclaration';

const ReadExcel = ({ onSuccess, company }) => {
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const extension = file.name.split('.').pop().toLowerCase();

    if (extension === 'csv') {
      parseCSV(file);
    } else if (['xls', 'xlsx'].includes(extension)) {
      parseExcel(file);
    } else {
      alert('Unsupported file type. Please upload a CSV or Excel file.');
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        extractEmails(results.data);
      },
    });
  };

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = jsonData[0];
      const emailIndex = headers.indexOf('email');

      if (emailIndex === -1) {
        alert('No email column found.');
        return;
      }

      const emails = jsonData
        .slice(1)
        .map((row) => ({
          email: row[emailIndex],
        }))
        .filter((row) => isValidEmail(row.email));

      setParsedData(emails);
    };

    reader.readAsArrayBuffer(file);
  };

  const extractEmails = (data) => {
    const emailData = data
      .filter((row) => isValidEmail(row.email))
      .map((row) => ({
        email: row.email,
      }));
    setParsedData(emailData);
  };

  const onSendInvite = async () => {
    try {
      const body = {
        users: parsedData,
        company,
      };
      const resp = await ADD_USER(body);
      if (resp.data.existingEmails.length) {
        showErrorTost(`Below email/s already exists${'\n'}${JSON.stringify(resp.data.existingEmails)}`);
      }
      onSuccess();
      showSuccessTost('Employee added successfully.');
    } catch (error) {
      showErrorTost('Error in adding employee.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />

      {parsedData.length > 0 && (
        <div>
          <Button onClick={onSendInvite} disabled={loading} sx={{ my: 1 }} variant="contained" size="small">
            Send invite
          </Button>
          <table style={{ width: '100%', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>#</th>
                <th style={{ width: '50%' }}>Email</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReadExcel;
