import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';
import Wrapper from 'src/components/common/wrapper';
import SigninForm from 'src/components/form/SigninForm';

const SigninPage = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Jacques Admin </title>
      </Helmet>
      <Typography sx={{ mb: 3 }} variant="h4" color={'primary'}>
        Sign in to Jacques Admin
      </Typography>
      <SigninForm />
    </Wrapper>
  );
};

export default SigninPage;
