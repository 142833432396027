// @mui
import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 40,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  marginBottom: 10,
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  paddingLeft: 10,
});

export const DropArea = styled('div')(({ theme, fielerror, disabled }) => ({
  border: '1px dashed',
  borderColor: fielerror ? 'red' : '#80808099',
  cursor: 'pointer',
  padding: '10px',
  borderRadius: '6px',
  color: '#808080bd',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: '160px',
  width: '100%',
  backgroundColor: disabled ? '#f1f1f1' : 'transparent',
  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
}));
