import UserPage from 'src/pages/User';
import DashboardAppPage from 'src/pages/Dashboard';
import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboard/DashboardLayout';
import ChatPage from 'src/pages/Chat';
import PrivacyPage from 'src/pages/Privacy';
import CompanyPage from 'src/pages/Company';
import FaqPage from 'src/pages/Faq';
import FeedbackPage from 'src/pages/Feedback';
import PromptPage from 'src/pages/Prompt';
import PackagePage from 'src/pages/Package';
import CustomPackagePage from 'src/pages/CustomPackage';
import FooterPage from 'src/pages/Footer';
import HomePage from 'src/pages/Home';
import AboutPage from 'src/pages/About';
import SettingsPage from 'src/pages/Settings';
import BlogPage from 'src/pages/Blog';
import CompanyDetails from 'src/pages/CompanyDetails';

export default function HomeRouter() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={`/`} replace />} />
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<DashboardAppPage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/company" element={<CompanyPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/feedback" element={<FeedbackPage />} />
        <Route path="/prompt" element={<PromptPage />} />
        <Route path="/package" element={<PackagePage />} />
        <Route path="/custom-package" element={<CustomPackagePage />} />
        <Route path="/footer" element={<FooterPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="company/:id" element={<CompanyDetails />} />
      </Route>
    </Routes>
  );
}
