import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ICONS } from 'src/assets/library';
import { bulkActionFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextField from 'src/components/formFields/textField';
import LoaderButton from 'src/components/loaderButton/loaderButton';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { BULK_ACTION_EMPLOYEE } from 'src/apiService/apiDeclaration';

const EmployeeActionForm = ({ company, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    words: 0,
    credits: 0,
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(bulkActionFormSchema),
    defaultValues,
  });

  const onSubmit = async (body) => {
    setLoading(true);
    try {
      await BULK_ACTION_EMPLOYEE(company, body);
      showSuccessTost('User updated successfully.');
      onSuccess();
    } catch (error) {
      showErrorTost('Error in updating user.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <CustomTextField
            label={'Words'}
            required={false}
            type="number"
            icon={ICONS.words}
            controller={{
              name: 'words',
              control: control,
              errors: errors?.words,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={'Credits'}
            required={false}
            type="number"
            icon={ICONS.words}
            controller={{
              name: 'credits',
              control: control,
              errors: errors?.credits,
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <LoaderButton disabled={!isValid || !isDirty} loading={loading} buttonText={'Add'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default EmployeeActionForm;
