import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import RichText from '../formFields/richText';
import { faqFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderButton from '../loaderButton/loaderButton';
import { UPDATE_FAQ, CREATE_FAQ } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';
import CustomTextField from '../formFields/textField';

const FaqForm = ({ data, onSuccess, company }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    question: { en: data?.question.en || '', fr: data?.question.fr || '' },
    answer: { en: data?.answer.en || '', fr: data?.answer.fr || '' },
    priority: data?.priority || 0,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(faqFormSchema),
    defaultValues,
  });

  const onUpdate = async (body) => {
    const payload = { ...body, ...(company ? { company } : {}) };
    setLoading(true);
    try {
      if (data?._id) {
        await UPDATE_FAQ(data._id, payload);
      } else {
        await CREATE_FAQ(payload);
      }
      onSuccess();
      showSuccessTost('FAQ updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating FAQ.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <CustomTextField
            type="number"
            label={'Priority'}
            controller={{
              name: 'priority',
              control: control,
              errors: errors.priority,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Question (English)'}
            controller={{
              name: 'question.en',
              control: control,
              errors: errors?.question?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Question (French)'}
            controller={{
              name: 'question.fr',
              control: control,
              errors: errors?.question?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Answer (English)'}
            controller={{
              name: 'answer.en',
              control: control,
              errors: errors?.answer?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Answer (French)'}
            controller={{
              name: 'answer.fr',
              control: control,
              errors: errors?.answer?.fr,
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <LoaderButton loading={loading} buttonText={data?._id ? 'Update' : 'add'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default FaqForm;
