import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import RichText from '../formFields/richText';
import { packageFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderButton from '../loaderButton/loaderButton';
import { CREATE_PACKAGE, UPDATE_PACKAGE } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';
import CustomTextField from '../formFields/textField';
import { ICONS } from 'src/assets/library';

const PackageForm = ({ data, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const defaultValues = data?._id
    ? {
        serialNumber: data.serialNumber,
        title: data.title,
        description: data.description,
        saveText: data.saveText,
        helpText: data.helpText,
        creditHelpText: data.creditHelpText,
        words: data.words,
        credits: data.credits,
        stripePriceId: data.stripePriceId,
        buyPrice: data.buyPrice,
        salePrice: data.salePrice,
        discount: data.discount,
      }
    : {};

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(packageFormSchema),
    defaultValues,
  });

  const onUpdate = async (body) => {
    setLoading(true);
    try {
      if (data?._id) {
        await UPDATE_PACKAGE(data._id, body);
      } else {
        await CREATE_PACKAGE(body);
      }
      onSuccess();
      showSuccessTost('Package data updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating Package data.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            type="number"
            label={'Serial Number'}
            controller={{
              name: 'serialNumber',
              control: control,
              errors: errors.serialNumber,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            type="number"
            label={'Discount'}
            controller={{
              name: 'discount',
              control: control,
              errors: errors.discount,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Title (English)'}
            controller={{
              name: 'title.en',
              control: control,
              errors: errors?.title?.en,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Title (French)'}
            controller={{
              name: 'title.fr',
              control: control,
              errors: errors?.title?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Description (English)'}
            controller={{
              name: 'description.en',
              control: control,
              errors: errors?.description?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Description (French)'}
            controller={{
              name: 'description.fr',
              control: control,
              errors: errors?.description?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Save Text (English)'}
            controller={{
              name: 'saveText.en',
              control: control,
              errors: errors?.saveText?.en,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Save Text (French)'}
            controller={{
              name: 'saveText.fr',
              control: control,
              errors: errors?.saveText?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Help Text (English)'}
            controller={{
              name: 'helpText.en',
              control: control,
              errors: errors?.helpText?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Help Text (French)'}
            controller={{
              name: 'helpText.fr',
              control: control,
              errors: errors?.helpText?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Credit Help Text (English)'}
            controller={{
              name: 'creditHelpText.en',
              control: control,
              errors: errors?.creditHelpText?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Credit Help Text (French)'}
            controller={{
              name: 'creditHelpText.fr',
              control: control,
              errors: errors?.creditHelpText?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12} mb={-2}>
          <Typography variant={'body'} fontWeight={600}>
            Buy Price
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Monthly'}
            icon={ICONS.price}
            controller={{
              name: 'buyPrice.MONTHLY',
              control: control,
              errors: errors?.buyPrice?.MONTHLY,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Yearly'}
            icon={ICONS.price}
            controller={{
              name: 'buyPrice.YEARLY',
              control: control,
              errors: errors?.buyPrice?.YEARLY,
            }}
          />
        </Grid>
        <Grid item xs={12} mb={-2}>
          <Typography variant={'body'} fontWeight={600}>
            Sale Price
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Monthly'}
            icon={ICONS.price}
            controller={{
              name: 'salePrice.MONTHLY',
              control: control,
              errors: errors?.salePrice?.MONTHLY,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Yearly'}
            icon={ICONS.price}
            controller={{
              name: 'salePrice.YEARLY',
              control: control,
              errors: errors?.salePrice?.YEARLY,
            }}
          />
        </Grid>
        <Grid item xs={12} mb={-2}>
          <Typography variant={'body'} fontWeight={600}>
            Stripe Price Id
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Monthly'}
            controller={{
              name: 'stripePriceId.MONTHLY',
              control: control,
              errors: errors?.stripePriceId?.MONTHLY,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Yearly'}
            controller={{
              name: 'stripePriceId.YEARLY',
              control: control,
              errors: errors?.stripePriceId?.YEARLY,
            }}
          />
        </Grid>
        <Grid item xs={12} mb={-2}>
          <Typography variant={'body'} fontWeight={600}>
            Words
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            disabled
            label={'Monthly'}
            icon={ICONS.words}
            controller={{
              name: 'words.MONTHLY',
              control: control,
              errors: errors?.words?.MONTHLY,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            disabled
            label={'Yearly'}
            icon={ICONS.words}
            controller={{
              name: 'words.YEARLY',
              control: control,
              errors: errors?.words?.YEARLY,
            }}
          />
        </Grid>
        <Grid item xs={12} mb={-2}>
          <Typography variant={'body'} fontWeight={600}>
            Credits
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Monthly'}
            icon={ICONS.words}
            controller={{
              name: 'credits.MONTHLY',
              control: control,
              errors: errors?.credits?.MONTHLY,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Yearly'}
            icon={ICONS.words}
            controller={{
              name: 'credits.YEARLY',
              control: control,
              errors: errors?.credits?.YEARLY,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <LoaderButton loading={loading} disabled={loading} buttonText={'Update'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default PackageForm;
