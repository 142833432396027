import { Card, Grid, Typography } from '@mui/material';
import { ICONS } from 'src/assets/library';
import BlogForm from 'src/components/form/blogForm';
import BlogPageForm from 'src/components/form/blogPageForm';
import CustomLoader from 'src/components/loader/loader';
import MenuPopover from 'src/components/menu/menu';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import CustomTable from 'src/components/table/table';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { blogColumns } from 'src/constants/columnConfig';
import useApiGetter from 'src/hooks/useApiGetter';
import { useModal } from 'src/hooks/useModal';
import usePagination from 'src/hooks/usePagination';
import { DELETE_BLOG, GET_BLOG_LIST, GET_BLOG_PAGE } from 'src/apiService/apiDeclaration';

const BlogPage = () => {
  const { data: blogPageData } = useApiGetter(GET_BLOG_PAGE);
  const updatedBlogColumn = [...blogColumns];
  const { setDialogData, handleModalClose } = useModal();
  const { paginationModel, setPaginationModel, loading, data, refresh } = usePagination(GET_BLOG_LIST);

  const onSuccess = () => {
    handleModalClose();
    refresh();
  };

  const onDelete = async (id) => {
    try {
      await DELETE_BLOG(id);
      onSuccess();
      showSuccessTost('Blog deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error in deleting blog');
    }
  };

  updatedBlogColumn.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: ICONS.edit,
              name: 'Edit Blog',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Update Blog',
                  child: <BlogForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this blog?',
                  onConfirm: () => onDelete(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Blog'} />
      <Grid item xs={12}>
        <PageHeader
          pageTitle={'Blog'}
          buttonTitle={'Add Blog'}
          onButtonClick={() =>
            setDialogData({
              showModal: true,
              title: 'Add new Blog',
              child: <BlogForm onSuccess={onSuccess} />,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedBlogColumn}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <Typography variant="h6" mb={1}>
            Blog Page Data
          </Typography>
          {!blogPageData ? <CustomLoader /> : <BlogPageForm data={blogPageData} />}
        </Card>
      </Grid>
    </Grid>
  );
};

export default BlogPage;
