import { useState, useEffect } from 'react';

const usePagination = (fetchData, initialParams = {}) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
  const [queryParams, setQuerryParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ rows: [], rowCount: 0 });

  const getQueryString = (params) => {
    return Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  };

  const fetchDataFromServer = async () => {
    setLoading(true);
    try {
      const params = { page: paginationModel.page + 1, pageSize: paginationModel.pageSize, ...queryParams };
      const queryString = getQueryString(params);
      const { data } = await fetchData(queryString);
      setData({ rows: data.rows, rowCount: data.rowCount });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataFromServer();

    // eslint-disable-next-line
  }, [paginationModel, queryParams]);

  const refresh = () => {
    fetchDataFromServer();
  };
  return {
    paginationModel,
    setPaginationModel,
    setQuerryParams,
    loading,
    data,
    refresh,
  };
};

export default usePagination;
