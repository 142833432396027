import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import RichText from '../formFields/richText';
import { promptFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderButton from '../loaderButton/loaderButton';
import { UPDATE_PROMPT, CREATE_PROMPT } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';
import CustomTextField from '../formFields/textField';

const PromptForm = ({ data, onSuccess, parent }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = data?._id
    ? {
        title: data.title,
        description: data.description,
        priority: data.priority,
      }
    : {};

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(promptFormSchema),
    defaultValues,
  });

  const onUpdate = async (body) => {
    const payload = { ...body, ...(parent ? { parent } : {}) };
    setLoading(true);
    try {
      if (data?._id) {
        await UPDATE_PROMPT(data._id, payload);
      } else {
        await CREATE_PROMPT(payload);
      }
      onSuccess();
      showSuccessTost('Data updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating data.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Priority'}
            controller={{
              name: 'priority',
              control: control,
              errors: errors.priority,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <RichText
            label={'Title (English)'}
            controller={{
              name: 'title.en',
              control: control,
              errors: errors?.title?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Title (French)'}
            controller={{
              name: 'title.fr',
              control: control,
              errors: errors?.title?.fr,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <RichText
            label={'Description (English)'}
            controller={{
              name: 'description.en',
              control: control,
              errors: errors?.description?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Description (French)'}
            controller={{
              name: 'description.fr',
              control: control,
              errors: errors?.description?.fr,
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <LoaderButton loading={loading} buttonText={data?._id ? 'Update' : 'add'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default PromptForm;
