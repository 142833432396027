import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { assistantSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelectField from '../formFields/selectField';
import { CREATE_ASSISTANT, UPDATE_ASSISTANT } from 'src/apiService/apiDeclaration';
import CustomTextField from 'src/components/formFields/textField';
import LoaderButton from 'src/components/loaderButton/loaderButton';
import { MODELS, modelArray, showErrorTost, showSuccessTost } from 'src/constants';

const AssistantForm = ({ data, onSuccess, company }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = data?._id
    ? {
        name: data.name,
        model: data.model,
        instructions: data.instructions,
      }
    : { model: MODELS['GPT-4O'] };
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(assistantSchema),
    defaultValues,
  });

  const onSubmit = async (body) => {
    setLoading(true);
    try {
      const payload = { ...body, ...(company ? { company } : {}) };
      if (data?._id) {
        await UPDATE_ASSISTANT(data._id, payload);
      } else {
        await CREATE_ASSISTANT(payload);
      }
      showSuccessTost('Assistant updated successfully.');
      onSuccess();
    } catch (error) {
      showErrorTost('Error in updating assistant.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <CustomTextField
            label={'Name'}
            multiline
            controller={{
              name: 'name',
              control: control,
              errors: errors.name,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelectField
            label={'Model'}
            optionsArray={modelArray}
            controller={{
              name: 'model',
              control: control,
              errors: errors.model,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={'Instructions'}
            multiline
            minRows={4}
            controller={{
              name: 'instructions',
              control: control,
              errors: errors.instructions,
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <LoaderButton
            disabled={!isValid || !isDirty}
            loading={loading}
            buttonText={data?._id ? 'Update' : 'Add'}
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AssistantForm;
