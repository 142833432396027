import { useState } from 'react';
import FileItem from '../common/fileItem';
import { ICONS } from 'src/assets/library';
import { Button, Grid, Typography } from '@mui/material';
import DropzoneArea from '../common/dropzone';
import { useParams } from 'react-router-dom';
import { useModal } from 'src/hooks/useModal';
import useApiGetter from 'src/hooks/useApiGetter';
import PageHeader from '../pageHeader/PageHeader';
import AssistantForm from '../form/assistantForm';
import LoaderButton from '../loaderButton/loaderButton';
import FileService from 'src/service/firebase/firebaseService';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { ASSISTANT_TRAINING, DELETE_ASSISTANT, GET_ASSISTANT, UPLOAD_FILE } from 'src/apiService/apiDeclaration';

const AssistantSection = () => {
  const { id } = useParams();
  const { data, refresh } = useApiGetter(GET_ASSISTANT, id);
  const { setDialogData, handleModalClose } = useModal();
  const [loading, setLoadig] = useState(false);

  const onSuccess = () => {
    handleModalClose();
    refresh();
  };
  const onDelete = async () => {
    try {
      await DELETE_ASSISTANT(data._id);
      showSuccessTost('Assistant updated successfully.');
      onSuccess();
    } catch (error) {
      showErrorTost('Error in deleting assistant.');
    }
  };

  const getFiles = async (files) => {
    setLoadig(true);
    try {
      const url = await FileService.uploadFile(files[0]);
      await UPLOAD_FILE({ url, name: files[0].name }, data._id);
      refresh();
      showSuccessTost('File uploaded successfully');
    } catch (error) {
      showErrorTost('Error in uploading file');
      console.log(error);
    } finally {
      setLoadig(false);
    }
  };

  const doTraining = async () => {
    setLoadig(true);
    try {
      await ASSISTANT_TRAINING(data._id);
      refresh();
      showSuccessTost('Training complete');
    } catch (error) {
      showErrorTost('Traininng unsuccessfull');
      console.log(error);
    } finally {
      setLoadig(false);
    }
  };

  return (
    <Grid item xs={12}>
      <PageHeader
        pageTitle={`Assistant ${data?.name || ''}`}
        buttonTitle={data?._id ? 'Update assistant' : 'Add assistant'}
        onButtonClick={() => {
          setDialogData({
            showModal: true,
            title: data?._id ? 'Update assistant' : 'Add new assistant',
            child: <AssistantForm data={data} onSuccess={onSuccess} company={id} />,
          });
        }}
      />
      {data?._id && (
        <Grid container spacing={2}>
          <Grid item mt={2} xs={12} display={'flex'} justifyContent={'flex-end'}>
            <Button
              variant={'contained'}
              onClick={() =>
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this Assistant?',
                  onConfirm: () => onDelete(),
                })
              }
              size="small"
              color="error"
              startIcon={ICONS.deleteWhite}
            >
              delete
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DropzoneArea getFiles={getFiles} disabled={loading} />
          </Grid>
          {data.files.length > 0 && (
            <Grid item xs={12}>
              <Typography fontWeight={600}>Assistant files</Typography>
            </Grid>
          )}

          {data.files.map(({ name, bytes, id }, index) => (
            <Grid key={index} item md={2} sm={4} xs={6}>
              <FileItem name={name} fileId={id} bytes={bytes} onSuccess={onSuccess} />
            </Grid>
          ))}
          {data.files.length > 0 && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={2}>
                  <LoaderButton
                    onClick={doTraining}
                    buttonText={'Train Assistant'}
                    disabled={!data.files.some((file) => file.tuned === false) || loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default AssistantSection;
