import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import BookIcon from '@mui/icons-material/Book';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import Person2Icon from '@mui/icons-material/Person2';
import CommentIcon from '@mui/icons-material/Comment';
import ArticleIcon from '@mui/icons-material/Article';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TerminalIcon from '@mui/icons-material/Terminal';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InventoryIcon from '@mui/icons-material/Inventory';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ApartmentIcon from '@mui/icons-material/Apartment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
export const IMAGES = {
  logo: 'https://firebasestorage.googleapis.com/v0/b/jacques-bf86d.appspot.com/o/jacquesAssets%2FchatbotAvatar.png?alt=media&token=e95bcb92-be91-431c-8e12-fce4317b75c3',
};

export const ICONS = {
  add: <AddIcon />,
  email: <EmailIcon />,
  password: <LockIcon />,
  visible: <VisibilityIcon />,
  hide: <VisibilityOffIcon />,
  menu: <MenuIcon />,
  menuDot: <MoreVertIcon />,
  dashboard: <DashboardIcon />,
  user: <Person2Icon />,
  chat: <ChatIcon />,
  privacy: <SafetyCheckIcon />,
  delete: <DeleteIcon color="error" />,
  deleteWhite: <DeleteIcon style={{ color: '#fff' }} />,
  edit: <EditNoteIcon />,
  words: <LabelIcon />,
  model: <ModelTrainingIcon />,
  date: <CalendarTodayIcon />,
  blog: <BookIcon />,
  company: <ApartmentIcon />,
  feedback: <CommentIcon />,
  home: <HomeIcon />,
  prompmt: <TerminalIcon />,
  package: <InventoryIcon />,
  faq: <LiveHelpIcon />,
  about: <InfoIcon />,
  footer: <CallToActionIcon />,
  settings: <SettingsIcon />,
  price: <AttachMoneyIcon />,
  rate: <TrendingUpIcon />,
  sort: <SwapVertIcon />,
  filter: <FilterAltIcon />,
  back: <ArrowBackIcon />,
  phone: <ContactPhoneIcon />,
  upload: <UploadFileIcon />,
  download: <DownloadForOfflineIcon />,
  file: (size) => <ArticleIcon style={{ fontSize: `${size}px` }} color="primary" />,
};
