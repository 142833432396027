import { useDropzone } from 'react-dropzone';
import { DropArea } from '../nav-section/styles';
import { Typography } from '@mui/material';

const DropzoneArea = ({ getFiles, disabled }) => {
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    getFiles(acceptedFiles);
  };

  const { getInputProps, getRootProps } = useDropzone({
    onError: (error) => console.log(error),
    onDrop,
    disabled: disabled,
    maxSize: 40000000,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
    },
    // multiple: true,
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} />
      <h5>DRAG AND DROP OR CLICK TO UPLOAD FILE</h5>
      <Typography mt={-3} variant="caption">
        Accepted files: .pdf, .doc, .docx, .txt, .xlx, .csv
      </Typography>
    </DropArea>
  );
};

export default DropzoneArea;
