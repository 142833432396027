import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import RichText from '../formFields/richText';
import { chatPageFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderButton from '../loaderButton/loaderButton';
import { UPDATE_CHAT } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';

const ChatForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    title: { en: data.title.en, fr: data.title.fr },
    description: { en: data.description.en, fr: data.description.fr },
    categoryTitle: { en: data.categoryTitle.en, fr: data.categoryTitle.fr },
    promptTitle: { en: data.promptTitle.en, fr: data.promptTitle.fr },
    catHeading: { en: data.catHeading.en, fr: data.catHeading.fr },
    showCategoryButton: { en: data.showCategoryButton.en, fr: data.showCategoryButton.fr },
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(chatPageFormSchema),
    defaultValues,
  });

  const onUpdate = async (body) => {
    setLoading(true);
    try {
      await UPDATE_CHAT(data._id, body);
      showSuccessTost('Chat data updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating chat data.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Title (English)'}
            controller={{
              name: 'title.en',
              control: control,
              errors: errors?.title?.en,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Title (French)'}
            controller={{
              name: 'title.fr',
              control: control,
              errors: errors?.title?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Description (English)'}
            controller={{
              name: 'description.en',
              control: control,
              errors: errors?.description?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Description (French)'}
            controller={{
              name: 'description.fr',
              control: control,
              errors: errors?.description?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Show Category heading (English)'}
            controller={{
              name: 'catHeading.en',
              control: control,
              errors: errors?.catHeading?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Show Category heading (French)'}
            controller={{
              name: 'catHeading.fr',
              control: control,
              errors: errors?.catHeading?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Show Category Button (English)'}
            controller={{
              name: 'showCategoryButton.en',
              control: control,
              errors: errors?.showCategoryButton?.en,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Show Category Button (French)'}
            controller={{
              name: 'showCategoryButton.fr',
              control: control,
              errors: errors?.showCategoryButton?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Category Title (English)'}
            controller={{
              name: 'categoryTitle.en',
              control: control,
              errors: errors?.categoryTitle?.en,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            label={'Category Title (French)'}
            controller={{
              name: 'categoryTitle.fr',
              control: control,
              errors: errors?.categoryTitle?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Prompt Title (English)'}
            controller={{
              name: 'promptTitle.en',
              control: control,
              errors: errors?.promptTitle?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Prompt Title (French)'}
            controller={{
              name: 'promptTitle.fr',
              control: control,
              errors: errors?.promptTitle?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <LoaderButton loading={loading} buttonText={'Update'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default ChatForm;
