import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import PieChart from 'src/components/chart/pieChart';
import StatCard from 'src/components/common/statCard';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import BarChart from 'src/components/chart/barChart';
import useApiGetter from 'src/hooks/useApiGetter';
import { GET_USER_STATS } from 'src/apiService/apiDeclaration';
import CustomLoader from 'src/components/loader/loader';

export default function DashboardAppPage() {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const { data } = useApiGetter(GET_USER_STATS);

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Jacques Admin'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={`Hi, Welcome back ${user?.name}`} />
      </Grid>
      {!data ? (
        <Grid item xs={12}>
          <CustomLoader />
        </Grid>
      ) : (
        <>
          <Grid item xs={6} sm={6} md={3}>
            <StatCard title="Daily" total={data.userCount.daily.toString()} />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <StatCard title="Weekly" total={data.userCount.weekly.toString()} color="info" />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <StatCard title="Monthly" total={data.userCount.monthly.toString()} color="warning" />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <StatCard title="Yearly" total={data.userCount.yearly.toString()} color="error" />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <BarChart
              title="Top 10 consumers"
              subheader="Based on words usage"
              chartData={data.top10ConsumersInTimeFrame.map((item) => {
                return { label: item.name, value: item.packageFeatures.burnWords };
              })}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <PieChart
              title="Total Users"
              subheader={data.userDistribution.totalUsers}
              chartData={[
                { label: 'Active', value: data.userDistribution.activeUsers },
                { label: 'Non Active', value: data.userDistribution.nonActiveUsers },
              ]}
              chartColors={[theme.palette.primary.main, theme.palette.error.main]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <BarChart
              title="Subscriptions"
              subheader="Based on subscribed users"
              chartData={data.usersBasedOnSubscription.map((item) => {
                return { label: `${item.packageName} (${item.packageValidity})`, value: item.userCount };
              })}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
