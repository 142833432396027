import { Card, Grid } from '@mui/material';
import { GET_SETTINGS } from 'src/apiService/apiDeclaration';
import SettingsForm from 'src/components/form/settingsForm';
import CustomLoader from 'src/components/loader/loader';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import useApiGetter from 'src/hooks/useApiGetter';

const SettingsPage = () => {
  const { data } = useApiGetter(GET_SETTINGS);

  console.log('data==', data);
  return (
    <Grid container spacing={2}>
      <TabTitle title={'Settings'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Settings'} />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>{!data ? <CustomLoader /> : <SettingsForm data={data} />}</Card>
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
