import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DELETE_COMPANY, GET_COMPANY_LIST } from 'src/apiService/apiDeclaration';
import { ICONS } from 'src/assets/library';
import CompanyForm from 'src/components/form/companyForm';
import MenuPopover from 'src/components/menu/menu';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import CustomTable from 'src/components/table/table';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { companyColumns } from 'src/constants/columnConfig';
import { useModal } from 'src/hooks/useModal';
import usePagination from 'src/hooks/usePagination';

const CompanyPage = () => {
  const navigate = useNavigate();
  const { setDialogData, handleModalClose } = useModal();
  const { paginationModel, setPaginationModel, loading, data, refresh } = usePagination(GET_COMPANY_LIST);
  const updatedCompanyColumn = [...companyColumns];

  const onSuccess = () => {
    handleModalClose();
    refresh();
  };

  const onDelete = async (id) => {
    try {
      await DELETE_COMPANY(id);
      onSuccess();
      showSuccessTost('Company deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error in deleting company');
    }
  };

  updatedCompanyColumn.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: ICONS.visible,
              name: 'View details',
              action: () => navigate(`/company/${row._id}`),
            },
            {
              icon: ICONS.edit,
              name: 'Edit company',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Update Company',
                  child: <CompanyForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this company?',
                  onConfirm: () => onDelete(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });
  return (
    <Grid container spacing={2}>
      <TabTitle title={'Company'} />
      <Grid item xs={12}>
        <PageHeader
          pageTitle={'Company'}
          buttonTitle={'Add company'}
          onButtonClick={() => {
            setDialogData({
              showModal: true,
              title: 'Add new company',
              child: <CompanyForm onSuccess={onSuccess} />,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedCompanyColumn}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyPage;
