import { Chip, Typography } from '@mui/material';
import { showDate } from '.';
import parse from 'html-react-parser';
const columnCommonCofig = { sortable: false, width: 150 };

export const userTableColumns = [
  { field: 'name', headerName: 'Name', ...columnCommonCofig, width: 200 },
  { field: 'email', headerName: 'Email', ...columnCommonCofig, width: 300 },
  {
    field: 'words',
    headerName: 'Words',
    ...columnCommonCofig,
    width: 120,
    renderCell: ({ row }) => row.packageFeatures.words,
  },
  {
    field: 'credits',
    headerName: 'Credits',
    ...columnCommonCofig,
    width: 120,
    renderCell: ({ row }) => row.packageFeatures?.credits || 0,
  },
  {
    field: 'title',
    headerName: 'Package',
    ...columnCommonCofig,
    width: 180,
    renderCell: ({ row }) =>
      row?.package?.type === 'CANCELLED' ? 'CANCELLED' : row?.package?.id ? parse(row.package.id.title.en) : '-',
  },
  {
    field: 'model',
    headerName: 'Model',
    ...columnCommonCofig,

    renderCell: ({ row }) => <Typography textTransform={'uppercase'}>{row.packageFeatures.model}</Typography>,
  },
  {
    field: 'expireOn',
    headerName: 'Expired On',
    ...columnCommonCofig,
    renderCell: ({ row }) => (row?.package?.expireOn ? showDate(row.package.expireOn) : '-'),
  },
];

export const userFeedbackColumns = [
  { field: 'name', headerName: 'Name', ...columnCommonCofig, width: 200 },
  { field: 'email', headerName: 'Email', ...columnCommonCofig, width: 300 },
  {
    field: 'issue',
    headerName: 'Issue',
    ...columnCommonCofig,
    width: 350,
  },
  {
    field: 'status',
    headerName: 'Status',
    ...columnCommonCofig,
    width: 200,
    renderCell: ({ row }) => (
      <Chip size="small" color={row.status === 'PENDING' ? 'warning' : 'success'} label={row.status} />
    ),
  },
];

export const faqColumns = [
  { field: 'priority', headerName: 'Priority', ...columnCommonCofig, flex: 1 },
  {
    field: 'question',
    headerName: 'Question (English)',
    ...columnCommonCofig,
    flex: 1,
    renderCell: ({ row }) => parse(row.question.en),
  },
];

export const blogColumns = [
  { field: 'priority', headerName: 'Priority', ...columnCommonCofig },
  {
    field: 'title',
    headerName: 'Title (English)',
    ...columnCommonCofig,
    width: 300,
    renderCell: ({ row }) => parse(row.title.en),
  },
  {
    field: 'authorName',
    headerName: 'Author name',
    ...columnCommonCofig,
    width: 300,
    renderCell: ({ row }) => parse(row.authorName),
  },
  {
    field: 'createdAt',
    headerName: 'Issue Date',
    ...columnCommonCofig,
    width: 200,
    renderCell: ({ row }) => showDate(row.createdAt),
  },
];

export const packageColumns = [
  {
    field: 'title',
    headerName: 'Title (English)',
    ...columnCommonCofig,
    width: 300,
    renderCell: ({ row }) => parse(row.title.en),
  },
  {
    field: 'validity',
    headerName: 'Validity',
    ...columnCommonCofig,
    width: 150,
    renderCell: () => 'Monthly',
  },
  {
    field: 'type',
    headerName: 'Type',
    ...columnCommonCofig,
    width: 150,
    renderCell: ({ row }) => parse(row.type),
  },
  {
    field: 'words',
    headerName: 'Words',
    ...columnCommonCofig,
    width: 200,
    renderCell: ({ row }) => row.words.MONTHLY,
  },
  {
    field: 'salePrice',
    headerName: 'Sale Price',
    ...columnCommonCofig,
    width: 150,
    renderCell: ({ row }) => row.salePrice.MONTHLY,
  },
  {
    field: 'buyPrice',
    headerName: 'Buy Price',
    ...columnCommonCofig,
    width: 150,
    renderCell: ({ row }) => row.buyPrice.MONTHLY,
  },
];

export const promptColumns = [
  {
    field: 'priority',
    headerName: 'Priority',
    ...columnCommonCofig,
    width: 180,
  },
  {
    field: 'title',
    headerName: 'Title (English)',
    ...columnCommonCofig,
    width: 450,
    renderCell: ({ row }) => parse(row.title.en),
  },
  {
    field: 'titleFr',
    headerName: 'Title (French)',
    ...columnCommonCofig,
    width: 450,
    renderCell: ({ row }) => parse(row.title.fr),
  },
];

export const companyColumns = [
  { field: 'name', headerName: 'Name', ...columnCommonCofig, width: 200 },
  { field: 'email', headerName: 'Email', ...columnCommonCofig, width: 300 },

  {
    field: 'number',
    headerName: 'Contact Number',
    ...columnCommonCofig,
    width: 180,
  },

  {
    field: 'words',
    headerName: 'Words',
    ...columnCommonCofig,
    width: 120,
    renderCell: ({ row }) => row.packageFeatures.words,
  },
  {
    field: 'model',
    headerName: 'Model',
    ...columnCommonCofig,

    renderCell: ({ row }) => <Typography textTransform={'uppercase'}>{row.packageFeatures.model}</Typography>,
  },
  {
    field: 'expireOn',
    headerName: 'Expired On',
    ...columnCommonCofig,
    renderCell: ({ row }) => showDate(row.package.expireOn),
  },
];

export const emplyeeTableColumns = [
  { field: 'name', headerName: 'Name', ...columnCommonCofig, width: 200 },
  { field: 'email', headerName: 'Email', ...columnCommonCofig, width: 300 },
  {
    field: 'words',
    headerName: 'Words',
    ...columnCommonCofig,
    width: 120,
    renderCell: ({ row }) => row.packageFeatures.words,
  },
  {
    field: 'credits',
    headerName: 'Credits',
    ...columnCommonCofig,
    width: 120,
    renderCell: ({ row }) => row.packageFeatures.credits,
  },
  {
    field: 'status',
    headerName: 'Status',
    ...columnCommonCofig,
    width: 180,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row?.companyDetails?.status}
        color={row?.companyDetails?.status === 'PENDING' ? 'warning' : 'success'}
      />
    ),
  },
  {
    field: 'model',
    headerName: 'Model',
    ...columnCommonCofig,

    renderCell: ({ row }) => <Typography textTransform={'uppercase'}>{row.packageFeatures.model}</Typography>,
  },
  {
    field: 'expireOn',
    headerName: 'Expired On',
    ...columnCommonCofig,
    renderCell: ({ row }) => (row.package?.expireOn ? showDate(row.package.expireOn) : 'Expired'),
  },
];
