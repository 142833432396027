import { useState } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { aboutPageFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { UPDATE_HOME_PAGE } from 'src/apiService/apiDeclaration';
import RichText from 'src/components/formFields/richText';
import LoaderButton from 'src/components/loaderButton/loaderButton';

const HomePageForm = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    start: { en: data.start.en, fr: data.start.fr },
    end: { en: data.end.en, fr: data.end.fr },
  };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(aboutPageFormSchema),
    defaultValues,
  });
  const {
    formState: { errors, isValid, isDirty },
    control,
    handleSubmit,
  } = methods;

  const onUpdate = async (body) => {
    setLoading(true);
    try {
      await UPDATE_HOME_PAGE(data._id, body);
      showSuccessTost('Home page updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating home page data.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onUpdate)}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6}>
            <RichText
              label={'Start (English)'}
              controller={{
                name: 'start.en',
                control: control,
                errors: errors?.start?.en,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RichText
              label={'Start (French)'}
              controller={{
                name: 'start.fr',
                control: control,
                errors: errors?.start?.fr,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              label={'End (English)'}
              controller={{
                name: 'end.en',
                control: control,
                errors: errors?.end?.en,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              label={'End (French)'}
              controller={{
                name: 'end.fr',
                control: control,
                errors: errors?.end?.fr,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LoaderButton disabled={!isValid || !isDirty} loading={loading} buttonText={'Update'} type="submit" />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default HomePageForm;
