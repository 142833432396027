import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import RichText from '../formFields/richText';
import { privacyFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderButton from '../loaderButton/loaderButton';
// import PreventReload from '../common/preventReload';
import { useState } from 'react';
import { UPDATE_PRIVACY } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';

const PrivacyForm = ({ data }) => {
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    title: { en: data.title.en, fr: data.title.fr },
    description: { en: data.description.en, fr: data.description.fr },
  };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(privacyFormSchema),
    defaultValues,
  });
  const {
    formState: { errors, isValid, isDirty },
    control,
    handleSubmit,
  } = methods;

  const onUpdate = async (body) => {
    setLoading(true);
    try {
      await UPDATE_PRIVACY(data._id, body);
      showSuccessTost('Privacy updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating Privacy.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      {/* <PreventReload /> */}
      <form onSubmit={handleSubmit(onUpdate)}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6}>
            <RichText
              label={'Title (English)'}
              controller={{
                name: 'title.en',
                control: control,
                errors: errors?.title?.en,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RichText
              label={'Title (French)'}
              controller={{
                name: 'title.fr',
                control: control,
                errors: errors?.title?.fr,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              label={'Description (English)'}
              controller={{
                name: 'description.en',
                control: control,
                errors: errors?.description?.en,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              label={'Description (French)'}
              controller={{
                name: 'description.fr',
                control: control,
                errors: errors?.description?.fr,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LoaderButton disabled={!isValid || !isDirty} loading={loading} buttonText={'Update'} type="submit" />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default PrivacyForm;
