import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import RichText from '../formFields/richText';
import { blogFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderButton from '../loaderButton/loaderButton';
import { UPDATE_BLOG, CREATE_BLOG } from 'src/apiService/apiDeclaration';
import { formatDate, showErrorTost, showSuccessTost } from 'src/constants';
import CustomTextField from '../formFields/textField';

const BlogForm = ({ data, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    title: { en: data?.title.en || '', fr: data?.title.fr || '' },
    blog: { en: data?.blog.en || '', fr: data?.blog.fr || '' },
    authorDescription: { en: data?.authorDescription.en || '', fr: data?.authorDescription.fr || '' },
    authorName: data?.authorName || '',
    priority: data?.priority || 0,
    createdAt: formatDate(data?.createdAt) || formatDate(new Date()),
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(blogFormSchema),
    defaultValues,
  });

  const onUpdate = async (body) => {
    setLoading(true);
    try {
      if (data?._id) {
        await UPDATE_BLOG(data._id, body);
      } else {
        await CREATE_BLOG(body);
      }
      onSuccess();
      showSuccessTost('Blog updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating Blog.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <CustomTextField
            type="number"
            label={'Priority'}
            controller={{
              name: 'priority',
              control: control,
              errors: errors.priority,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            type="date"
            label={'Issue Date'}
            controller={{
              name: 'createdAt',
              control: control,
              errors: errors.createdAt,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Title (English)'}
            controller={{
              name: 'title.en',
              control: control,
              errors: errors?.title?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Title (French)'}
            controller={{
              name: 'title.fr',
              control: control,
              errors: errors?.title?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Author Name'}
            controller={{
              name: 'authorName',
              control: control,
              errors: errors.authorName,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Author Description (English)'}
            controller={{
              name: 'authorDescription.en',
              control: control,
              errors: errors?.authorDescription?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Author Description (French)'}
            controller={{
              name: 'authorDescription.fr',
              control: control,
              errors: errors?.authorDescription?.fr,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <RichText
            label={'Blog (English)'}
            controller={{
              name: 'blog.en',
              control: control,
              errors: errors?.blog?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Blog (French)'}
            controller={{
              name: 'blog.fr',
              control: control,
              errors: errors?.blog?.fr,
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <LoaderButton loading={loading} buttonText={data?._id ? 'Update' : 'add'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default BlogForm;
