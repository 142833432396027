import { useState, useEffect } from 'react';

const useApiGetter = (fetchData, params) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  //   const getQueryString = (params) => {
  //     return Object.keys(params)
  //       .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  //       .join('&');
  //   };

  const fetchDataFromServer = async () => {
    setLoading(true);
    try {
      const { data } = await fetchData(params || '');
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataFromServer();
    // eslint-disable-next-line
  }, [fetchData]);

  const refresh = () => {
    fetchDataFromServer();
  };
  return {
    loading,
    data,

    refresh,
  };
};

export default useApiGetter;
