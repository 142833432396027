import { Card, CardActions, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import byteSize from 'byte-size';
import MenuPopover from '../menu/menu';
import { ICONS } from 'src/assets/library';
import { useModal } from 'src/hooks/useModal';
import { DELETE_FILE } from 'src/apiService/apiDeclaration';

const FileItem = ({ name, bytes, fileId, onSuccess }) => {
  const { setDialogData } = useModal();

  const onDelete = async () => {
    try {
      await DELETE_FILE(fileId);
      onSuccess();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card>
      <CardHeader
        sx={{
          px: 1,
          py: 1,
          '.MuiCardHeader-content': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        action={
          <MenuPopover
            menuArray={[
              {
                icon: ICONS.delete,
                name: 'Delete Permanentaly',
                action: () => {
                  setDialogData({
                    showModal: true,
                    description: 'Are you sure you want to delete this file?',
                    onConfirm: () => onDelete(),
                  });
                },
              },
            ]}
          />
        }
        title={
          <Tooltip title={name} arrow>
            <Typography noWrap variant="body2" fontWeight={700}>
              {name}
            </Typography>
          </Tooltip>
        }
      />
      <Grid display={'flex'} justifyContent={'center'} alignItems={'center'}>
        {ICONS.file(70)}
      </Grid>
      <CardActions>
        <Typography variant="caption">{`Size: ${byteSize(bytes).value} ${byteSize(bytes).unit}`}</Typography>
      </CardActions>
    </Card>
  );
};

export default FileItem;
