import { Grid } from '@mui/material';
import { ICONS } from 'src/assets/library';
import { useModal } from 'src/hooks/useModal';
import MenuPopover from 'src/components/menu/menu';
import usePagination from 'src/hooks/usePagination';
import CustomTable from 'src/components/table/table';
import TabTitle from 'src/components/tabTitle/tabTitle';
import PackageForm from 'src/components/form/packageForm';
import { packageColumns } from 'src/constants/columnConfig';
import PageHeader from 'src/components/pageHeader/PageHeader';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { DELETE_PACKAGE, GET_PACKAGE_LIST } from 'src/apiService/apiDeclaration';

const PackagePage = () => {
  const { setDialogData, handleModalClose } = useModal();
  const updatedPackageColumn = [...packageColumns];

  const { paginationModel, setPaginationModel, loading, data, refresh } = usePagination(GET_PACKAGE_LIST);

  const onSuccess = () => {
    handleModalClose();
    refresh();
  };

  const onDelete = async (id) => {
    try {
      await DELETE_PACKAGE(id);
      onSuccess();
      showSuccessTost('Package deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error in deleting package');
    }
  };

  updatedPackageColumn.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: ICONS.edit,
              name: 'Edit Package',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Update Package',
                  child: <PackageForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this package?',
                  onConfirm: () => onDelete(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Package'} />
      <Grid item xs={12}>
        <PageHeader
          pageTitle={'Package'}
          buttonTitle={'Add Package'}
          onButtonClick={() =>
            setDialogData({
              showModal: true,
              title: 'Add new package',
              child: <PackageForm />,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedPackageColumn}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
    </Grid>
  );
};

export default PackagePage;
