import { Card, Grid } from '@mui/material';
import TabTitle from 'src/components/tabTitle/tabTitle';
import PageHeader from 'src/components/pageHeader/PageHeader';
import ChatForm from 'src/components/form/chatForm';
import useApiGetter from 'src/hooks/useApiGetter';
import { GET_CHAT } from 'src/apiService/apiDeclaration';
import CustomLoader from 'src/components/loader/loader';

const ChatPage = () => {
  const { data } = useApiGetter(GET_CHAT);

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Chat'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Chat'} />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>{!data ? <CustomLoader /> : <ChatForm data={data} />}</Card>
      </Grid>
    </Grid>
  );
};

export default ChatPage;
