import moment from 'moment';
import { toast } from 'react-toastify';

export const constants = {
  authToken: 'token',
  pending: 'PENDING',
  joined: 'JOINED',
};
export const showSuccessTost = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const showErrorTost = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const showDate = (date) => moment(date).format('MMMM D, YYYY');
export const formatDate = (date) => moment(date).format('YYYY-MM-DD');

export const MODELS = {
  'GPT-4O': 'gpt-4o',
  'GPT-4O-MINI': 'gpt-4o-mini',
  'GPT-4-TURBO': 'gpt-4-turbo',
  'GPT-3.5-TURBO': 'gpt-3.5-turbo',
  'GPT-3.5-TURBO-0125': 'gpt-3.5-turbo-0125',
};

export const modelArray = [
  { value: MODELS['GPT-4O'], label: 'GPT-4O' },
  { value: MODELS['GPT-4O-MINI'], label: 'GPT-4O-MINI' },
  { value: MODELS['GPT-4-TURBO'], label: 'GPT-4-TURBO' },
  { value: MODELS['GPT-3.5-TURBO'], label: 'GPT-3.5-TURBO' },
  { value: MODELS['GPT-3.5-TURBO-0125'], label: 'GPT-3.5-TURBO-0125' },
];

export const sortArray = [
  { value: 'opt', label: 'Choose order' },
  { value: 'asc', label: 'Ascending' },
  { value: 'dsc', label: 'Descending' },
];

export const removeEmptyKeys = (obj) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) =>
      value !== null &&
      value !== undefined &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)
        ? { ...acc, [key]: value }
        : acc,
    {}
  );

export const getChangedFields = (dirtyFields, values) => {
  const extractChangedFields = (dirty, currentValues) => {
    return Object.keys(dirty).reduce((changed, key) => {
      if (typeof dirty[key] === 'object' && dirty[key] !== null) {
        changed[key] = currentValues[key];
      } else {
        changed[key] = currentValues[key];
      }
      return changed;
    }, {});
  };

  return extractChangedFields(dirtyFields, values);
};
