import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardHeader } from '@mui/material';
import useChart from 'src/hooks/useChart';
import { fNumber } from 'src/utils/formatNumber';

const BarChart = ({ title, subheader, chartData, ...other }) => {
  const sanitizeHTML = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };
  const chartLabels = chartData.map((i) => sanitizeHTML(i.label));

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    tooltip: {
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: { vertical: true, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={400} />
      </Box>
    </Card>
  );
};

export default BarChart;
