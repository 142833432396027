import { useState } from 'react';
import { Grid } from '@mui/material';
import { ICONS } from 'src/assets/library';
import { useModal } from 'src/hooks/useModal';
import MenuPopover from 'src/components/menu/menu';
import usePagination from 'src/hooks/usePagination';
import CustomTable from 'src/components/table/table';
import TabTitle from 'src/components/tabTitle/tabTitle';
import { ASSIGN_FREE_PACKAGE, DELETE_USER, GET_USER } from 'src/apiService/apiDeclaration';
import PageHeader from 'src/components/pageHeader/PageHeader';
import { userTableColumns } from 'src/constants/columnConfig';
import UpdateUserForm from 'src/components/form/editUserForm';
import { removeEmptyKeys, showErrorTost, showSuccessTost } from 'src/constants';
import UserFilterForm from 'src/components/form/filter/userFilterForm';
import CardWrapper from 'src/components/common/cardWrapper';

const UserPage = () => {
  const [filter, setFilter] = useState(false);
  const updatedUserTable = [...userTableColumns];
  const { setDialogData, handleModalClose } = useModal();
  const { paginationModel, setPaginationModel, loading, data, refresh, setQuerryParams } = usePagination(GET_USER);

  const onSuccess = () => {
    handleModalClose();
    refresh();
  };

  const onDelete = async (id) => {
    try {
      await DELETE_USER(id);
      onSuccess();
      showSuccessTost('User deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error inn deleting user');
    }
  };

  const asignFreePackage = async (userData) => {
    try {
      await ASSIGN_FREE_PACKAGE({ userData });
      onSuccess();
      showSuccessTost('Free package assigned successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error in assigning free package');
    }
  };

  updatedUserTable.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: ICONS.edit,
              name: 'Edit user',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Update user',
                  child: <UpdateUserForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.package,
              name: 'Assign free package',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to assign free package to this user?',
                  onConfirm: () => asignFreePackage({ name: row.name, email: row.email, _id: row._id }),
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this user?',
                  onConfirm: () => onDelete(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });

  const onFilter = (data) => {
    setQuerryParams(removeEmptyKeys(data));
  };
  const onReset = () => {
    setQuerryParams({});
  };

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Users'} />
      <Grid item xs={12}>
        <PageHeader
          pageTitle={'Users'}
          buttonTitle={'Filters'}
          onButtonClick={() => setFilter(!filter)}
          icon={ICONS.filter}
        />
      </Grid>
      {filter && (
        <Grid item xs={12}>
          <CardWrapper title={'Filter'}>
            <UserFilterForm loading={loading} onSubmit={onFilter} onReset={onReset} />
          </CardWrapper>
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedUserTable}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
    </Grid>
  );
};

export default UserPage;
