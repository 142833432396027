import { Grid } from '@mui/material';
import AssistantSection from 'src/components/company/assistantSection';
import EmployeeSection from 'src/components/company/employeeSection';
import FaqSection from 'src/components/company/faqSection';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';

const CompanyDetails = () => {
  return (
    <Grid container spacing={2}>
      <TabTitle title={'Company Details'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Details'} />
      </Grid>
      <EmployeeSection />
      <AssistantSection />
      <FaqSection />
    </Grid>
  );
};

export default CompanyDetails;
