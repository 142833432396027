import * as yup from 'yup';

const commonScheme = {
  filterDate: yup.date().nullable(),
  filterEmail: yup.string().email('Must be a valid email').nullable(),
  filterString: yup.string().nullable(),
  positiveIntegerOptional: yup.number().positive('Must be a positive number').min(0).integer('Must be an integer'),
  positiveInteger: yup
    .number()
    .positive('Must be a positive number')
    .integer('Must be an integer')
    .required('This is a required field'),
  date: yup.date().required('Date is required').typeError('Invalid date format'),
  positiveNum: yup.number().positive('Must be a positive number').required('This is a required field'),
  required: yup.string().required('This is a required field'),
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .max(12, 'Password must not exceed 12 characters')
    .required('Password is required'),
  passwordConfirmation: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .max(12, 'Password must not exceed 12 characters')
    .required('Password is required')
    .oneOf([yup.ref('password'), null], 'Password must match'),
};

export const signinFormScheme = yup.object().shape({
  email: commonScheme.email,
  password: commonScheme.password,
});

export const privacyFormSchema = yup.object().shape({
  title: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  description: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const aboutPageFormSchema = yup.object().shape({
  start: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  end: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const aboutItemFormSchema = yup.object().shape({
  head: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  body: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const homeItemFormSchema = yup.object().shape({
  head: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  body: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  footer: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const customPackageFormSchema = yup.object().shape({
  title: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  button: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  description: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const chatPageFormSchema = yup.object().shape({
  title: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  description: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  categoryTitle: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  promptTitle: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  catHeading: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  showCategoryButton: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const faqFormSchema = yup.object().shape({
  question: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  answer: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  priority: commonScheme.positiveInteger,
});

export const blogFormSchema = yup.object().shape({
  title: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  blog: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  authorDescription: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  priority: commonScheme.positiveInteger,
  authorName: commonScheme.required,
  createdAt: commonScheme.date,
});

export const promptFormSchema = yup.object().shape({
  title: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),

  description: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  priority: commonScheme.positiveInteger,
});

export const footerPageFormSchema = yup.object().shape({
  section1: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  section2: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  section3: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  section4: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  section5: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  section6: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const updateUserSchema = yup.object().shape({
  name: commonScheme.name,
  email: commonScheme.email,
  packageFeatures: yup.object().shape({
    model: commonScheme.required,
    words: commonScheme.positiveIntegerOptional,
    credits: commonScheme.positiveIntegerOptional,
  }),
  package: yup.object().shape({
    expireOn: commonScheme.date,
  }),
});

export const bulkActionFormSchema = yup.object().shape({
  words: commonScheme.positiveIntegerOptional,
  credits: commonScheme.positiveIntegerOptional,
});

export const companySchema = yup.object().shape({
  name: commonScheme.name,
  email: commonScheme.email,
  number: commonScheme.positiveNum,
  packageFeatures: yup.object().shape({
    model: commonScheme.required,
    words: commonScheme.positiveInteger,
    credits: commonScheme.positiveInteger,
  }),
  package: yup.object().shape({
    expireOn: commonScheme.date,
  }),
});

export const assistantSchema = yup.object().shape({
  name: commonScheme.name,
  instructions: commonScheme.name,
  model: commonScheme.required,
});

export const setingsSchema = yup.object().shape({
  tokens: commonScheme.positiveNum,
  price: commonScheme.positiveNum,
  rate: commonScheme.positiveNum,
  perCreditPriceMonthly: commonScheme.positiveNum,
  perCreditPriceYearly: commonScheme.positiveNum,
  stripeMonthlyCreditId: commonScheme.required,
  stripeYearlyCreditId: commonScheme.required,
  standAloneStripeCreditDescription: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
});

export const feedbackFormSchema = yup.object().shape({
  answer: commonScheme.required,
});

export const userFilterFormSchema = yup.object().shape({
  sort: commonScheme.filterString,
  email: commonScheme.filterString,
});

export const packageFormSchema = yup.object().shape({
  serialNumber: commonScheme.positiveInteger,
  discount: commonScheme.positiveNum,
  title: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  description: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  saveText: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  creditHelpText: yup.object().shape({
    en: commonScheme.required,
    fr: commonScheme.required,
  }),
  salePrice: yup.object().shape({
    MONTHLY: commonScheme.positiveNum,
    YEARLY: commonScheme.positiveNum,
  }),
  buyPrice: yup.object().shape({
    MONTHLY: commonScheme.positiveNum,
    YEARLY: commonScheme.positiveNum,
  }),
  stripePriceId: yup.object().shape({
    MONTHLY: commonScheme.required,
    YEARLY: commonScheme.required,
  }),
  words: yup.object().shape({
    MONTHLY: commonScheme.positiveInteger,
    YEARLY: commonScheme.positiveInteger,
  }),
  credits: yup.object().shape({
    MONTHLY: commonScheme.positiveInteger,
    YEARLY: commonScheme.positiveInteger,
  }),
});

export const addEmployeeFormScheme = yup.object().shape({
  email: commonScheme.email,
});
