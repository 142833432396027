import { Grid } from '@mui/material';
import { DELETE_FEEDBACK, GET_FEEDBACK } from 'src/apiService/apiDeclaration';
import { ICONS } from 'src/assets/library';
import FeedbackDetailForm from 'src/components/form/feedbackDetailForm';
import MenuPopover from 'src/components/menu/menu';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import CustomTable from 'src/components/table/table';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { userFeedbackColumns } from 'src/constants/columnConfig';
import { useModal } from 'src/hooks/useModal';
import usePagination from 'src/hooks/usePagination';

const FeedbackPage = () => {
  const { setDialogData, handleModalClose } = useModal();
  const updatedFeedbackTable = [...userFeedbackColumns];
  const { paginationModel, setPaginationModel, loading, data, refresh } = usePagination(GET_FEEDBACK);
  const onSuccess = () => {
    handleModalClose();
    refresh();
  };
  const onDelete = async (id) => {
    try {
      await DELETE_FEEDBACK(id);
      onSuccess();
      showSuccessTost('Feedback deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error inn deleting feedback');
    }
  };

  updatedFeedbackTable.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: ICONS.edit,
              name: 'Show details',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Feedback details',
                  child: <FeedbackDetailForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this feedback?',
                  onConfirm: () => onDelete(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Feedback'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Feedback'} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedFeedbackTable}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
    </Grid>
  );
};

export default FeedbackPage;
