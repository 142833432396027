import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import RichText from '../formFields/richText';
import { footerPageFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoaderButton from '../loaderButton/loaderButton';
import { UPDATE_FOOTER } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';

const FooterForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    section1: { en: data.section1.en, fr: data.section1.fr },
    section2: { en: data.section2.en, fr: data.section2.fr },
    section3: { en: data.section3.en, fr: data.section3.fr },
    section4: { en: data.section4.en, fr: data.section4.fr },
    section5: { en: data.section5.en, fr: data.section5.fr },
    section6: { en: data.section6.en, fr: data.section6.fr },
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(footerPageFormSchema),
    defaultValues,
  });

  const onUpdate = async (body) => {
    setLoading(true);
    try {
      await UPDATE_FOOTER(data._id, body);
      showSuccessTost('Footer data updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating footer data.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <RichText
            label={'Section A (English)'}
            controller={{
              name: 'section1.en',
              control: control,
              errors: errors?.section1?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section A (French)'}
            controller={{
              name: 'section1.fr',
              control: control,
              errors: errors?.section1?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section B (English)'}
            controller={{
              name: 'section2.en',
              control: control,
              errors: errors?.section2?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section B (French)'}
            controller={{
              name: 'section2.fr',
              control: control,
              errors: errors?.section2?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section C (English)'}
            controller={{
              name: 'section3.en',
              control: control,
              errors: errors?.section3?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section C (French)'}
            controller={{
              name: 'section3.fr',
              control: control,
              errors: errors?.section3?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section D (English)'}
            controller={{
              name: 'section4.en',
              control: control,
              errors: errors?.section4?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section D (French)'}
            controller={{
              name: 'section4.fr',
              control: control,
              errors: errors?.section4?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section E (English)'}
            controller={{
              name: 'section5.en',
              control: control,
              errors: errors?.section5?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section E (French)'}
            controller={{
              name: 'section5.fr',
              control: control,
              errors: errors?.section5?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section F (English)'}
            controller={{
              name: 'section6.en',
              control: control,
              errors: errors?.section6?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Section F (French)'}
            controller={{
              name: 'section6.fr',
              control: control,
              errors: errors?.section6?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <LoaderButton loading={loading} buttonText={'Update'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default FooterForm;
