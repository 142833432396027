import { Grid, Card } from '@mui/material';
import TabTitle from 'src/components/tabTitle/tabTitle';
import PrivacyForm from 'src/components/form/privacyForm';
import PageHeader from 'src/components/pageHeader/PageHeader';
import useApiGetter from 'src/hooks/useApiGetter';
import { GET_PRIVACY } from 'src/apiService/apiDeclaration';
import CustomLoader from 'src/components/loader/loader';

const PrivacyPage = () => {
  const { data } = useApiGetter(GET_PRIVACY);

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Privacy'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Privacy'} />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>{!data ? <CustomLoader /> : <PrivacyForm data={data} />}</Card>
      </Grid>
    </Grid>
  );
};

export default PrivacyPage;
