import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { DELETE_PROMPT, GET_PROMPT_LIST } from 'src/apiService/apiDeclaration';
import { ICONS } from 'src/assets/library';
import PromptForm from 'src/components/form/promptForm';
import MenuPopover from 'src/components/menu/menu';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import CustomTable from 'src/components/table/table';
import { removeEmptyKeys, showErrorTost, showSuccessTost } from 'src/constants';
import { promptColumns } from 'src/constants/columnConfig';
import { useModal } from 'src/hooks/useModal';
import usePagination from 'src/hooks/usePagination';

const PromptPage = () => {
  const { setDialogData, handleModalClose } = useModal();
  const [parent, setParent] = useState('');
  const updatedPromptColumn = [...promptColumns];

  const { paginationModel, setPaginationModel, loading, data, refresh, setQuerryParams } =
    usePagination(GET_PROMPT_LIST);

  const onSuccess = () => {
    handleModalClose();
    refresh();
  };

  const onDelete = async (id) => {
    try {
      await DELETE_PROMPT(id);
      onSuccess();
      showSuccessTost('Prompt deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error in deleting Prompt');
    }
  };

  useEffect(() => {
    setQuerryParams(removeEmptyKeys({ parent }));
  }, [parent, setQuerryParams]);

  updatedPromptColumn.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            ...(!parent
              ? [
                  {
                    icon: ICONS.visible,
                    name: 'View prompts',
                    action: () => setParent(row._id),
                  },
                ]
              : []),
            {
              icon: ICONS.edit,
              name: parent ? 'Update Prompt' : 'Update Category',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: parent ? 'Update Prompt' : 'Update Category',
                  child: <PromptForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: `Are you sure you want to delete this ${parent ? 'prompt' : 'category'}?`,
                  onConfirm: () => onDelete(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });
  return (
    <Grid container spacing={2}>
      <TabTitle title={'Prompt'} />
      <Grid item xs={12}>
        <PageHeader
          pageTitle={parent ? 'Prompt' : 'Category'}
          buttonTitle={parent ? 'Add Prompt' : 'Add Category'}
          onButtonClick={() =>
            setDialogData({
              showModal: true,
              title: parent ? 'Add new Prompt' : 'Add new Category',
              child: <PromptForm onSuccess={onSuccess} parent={parent} />,
            })
          }
        />
      </Grid>
      {parent && (
        <Grid item xs={12}>
          <Button onClick={() => setParent('')} size="small" variant="contained" color="info" startIcon={ICONS.back}>
            Back to categories
          </Button>
        </Grid>
      )}

      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedPromptColumn}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
    </Grid>
  );
};

export default PromptPage;
