import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextField from '../formFields/textField';
import LoaderButton from '../loaderButton/loaderButton';
import { addEmployeeFormScheme } from 'src/config/yup';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { ADD_USER } from 'src/apiService/apiDeclaration';

const AddUserForm = ({ onSuccess, company }) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addEmployeeFormScheme),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const body = {
        users: [{ ...data }],
        company,
      };
      const resp = await ADD_USER(body);
      if (resp.data.existingEmails.length) {
        showErrorTost(`Below email/s already exists${'\n'}${JSON.stringify(resp.data.existingEmails)}`);
      }
      onSuccess();
      showSuccessTost('Employee added successfully.');
    } catch (error) {
      showErrorTost('Error in adding employee.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <CustomTextField
            type="email"
            placeholder="name@domain.com"
            controller={{
              name: 'email',
              control: control,
              errors: errors.email,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <LoaderButton loading={loading} buttonText={'add'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddUserForm;
