import { Card, Grid } from '@mui/material';
import { GET_FOOTER } from 'src/apiService/apiDeclaration';
import FooterForm from 'src/components/form/footerForm';
import CustomLoader from 'src/components/loader/loader';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import useApiGetter from 'src/hooks/useApiGetter';

const FooterPage = () => {
  const { data } = useApiGetter(GET_FOOTER);

  return (
    <Grid container spacing={2}>
      <TabTitle title={'Footer'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Footer'} />
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>{!data ? <CustomLoader /> : <FooterForm data={data} />}</Card>
      </Grid>
    </Grid>
  );
};

export default FooterPage;
