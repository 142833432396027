import { useParams } from 'react-router-dom';
import { DELETE_FAQ, GET_FAQ_LIST } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { faqColumns } from 'src/constants/columnConfig';
import { useModal } from 'src/hooks/useModal';
import usePagination from 'src/hooks/usePagination';
import MenuPopover from '../menu/menu';
import { ICONS } from 'src/assets/library';
import FaqForm from '../form/faqForm';
import PageHeader from '../pageHeader/PageHeader';
import CustomTable from '../table/table';
import { Grid } from '@mui/material';

const FaqSection = () => {
  const { id } = useParams();

  const { paginationModel, setPaginationModel, loading, data, refresh } = usePagination(
    GET_FAQ_LIST,
    id ? { company: id } : {}
  );

  const { setDialogData, handleModalClose } = useModal();
  const updatedfaqColumn = [...faqColumns];
  const onSuccess = () => {
    handleModalClose();
    refresh();
  };

  const onDeleteFAQ = async (id) => {
    try {
      await DELETE_FAQ(id);
      onSuccess();
      showSuccessTost('FAQ deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error in deleting FAQ');
    }
  };

  updatedfaqColumn.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: ICONS.edit,
              name: 'Edit faq',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Update FAQ',
                  child: <FaqForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this FAQ?',
                  onConfirm: () => onDeleteFAQ(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });

  return (
    <>
      <Grid item xs={12}>
        <PageHeader
          pageTitle={'Faq'}
          buttonTitle={'Add faq'}
          onButtonClick={() => {
            setDialogData({
              showModal: true,
              title: 'Add new faq',
              child: <FaqForm company={id} onSuccess={onSuccess} />,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedfaqColumn}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
    </>
  );
};

export default FaqSection;
