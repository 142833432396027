import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ICONS } from 'src/assets/library';
import CustomSelectField from 'src/components/formFields/selectField';
import CustomTextField from 'src/components/formFields/textField';
import LoaderButton from 'src/components/loaderButton/loaderButton';
import { userFilterFormSchema } from 'src/config/yup';
import { sortArray } from 'src/constants';

const UserFilterForm = ({ onSubmit, loading, onReset }) => {
  const defaultValues = {
    email: '',
    expireOn: '',
    sort: 'opt',
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(userFilterFormSchema),
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={4}>
          <CustomTextField
            placeholder={'name@domain.com'}
            icon={ICONS.user}
            label={'Search by email'}
            controller={{
              name: 'email',
              control: control,
              errors: errors.email,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomTextField
            icon={ICONS.date}
            label={'Search by expiration date'}
            type="month"
            controller={{
              name: 'expireOn',
              control: control,
              errors: errors.expireOn,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomSelectField
            label={'Sort'}
            optionsArray={sortArray}
            controller={{
              name: 'sort',
              control: control,
              errors: errors.sort,
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <LoaderButton disabled={!isValid || !isDirty || loading} buttonText={'Apply Filters'} type="submit" />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <LoaderButton
            disabled={loading}
            onClick={() => {
              reset(defaultValues);
              onReset();
            }}
            buttonText={'reset'}
            color="warning"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default UserFilterForm;
