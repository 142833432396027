import React from 'react';
import { FormHelperText, MenuItem, Select, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomSelectField = ({ controller, label, optionsArray, required = true, ...props }) => {
  return (
    <div>
      {label && (
        <>
          <Typography variant="caption" fontWeight={800}>
            {label}
          </Typography>
          <br />
        </>
      )}

      {controller ? (
        <>
          <Controller
            control={controller.control}
            name={controller.name}
            rules={{ required }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
                fullWidth
                size="small"
                onChange={onChange}
                error={Boolean(controller.errors)}
                value={value ? value : ''}
                onBlur={onBlur}
                {...props}
              >
                {optionsArray.map(({ value, label }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {controller.errors && (
            <FormHelperText sx={{ color: 'error.main' }}>{controller.errors.message}</FormHelperText>
          )}
        </>
      ) : (
        <Select fullWidth size="small" {...props}>
          {optionsArray.map(({ value, label }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
};

export default CustomSelectField;
