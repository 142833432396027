import { useParams } from 'react-router-dom';
import { DELETE_USER, GET_USER } from 'src/apiService/apiDeclaration';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { emplyeeTableColumns } from 'src/constants/columnConfig';
import { useModal } from 'src/hooks/useModal';
import usePagination from 'src/hooks/usePagination';
import MenuPopover from '../menu/menu';
import { ICONS } from 'src/assets/library';
import PageHeader from '../pageHeader/PageHeader';
import CustomTable from '../table/table';
import { Grid } from '@mui/material';
import UpdateUserForm from '../form/editUserForm';
import AddUserForm from '../form/addEmployeeForm';
import ReadExcel from '../common/excelParser';
import EmployeeActionForm from '../form/employeeActionForm';

const EmployeeSection = () => {
  const { id } = useParams();

  const { paginationModel, setPaginationModel, loading, data, refresh } = usePagination(
    GET_USER,
    id ? { company: id } : {}
  );

  const { setDialogData, handleModalClose } = useModal();
  const updatedUserTable = [...emplyeeTableColumns];
  const onSuccess = () => {
    handleModalClose();
    refresh();
  };

  const onDelete = async (id) => {
    try {
      await DELETE_USER(id);
      onSuccess();
      showSuccessTost('Employee deleted successfully');
    } catch (error) {
      console.log(error);
      showErrorTost('Error in deleting employee');
    }
  };

  updatedUserTable.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: ICONS.edit,
              name: 'Edit employee',
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Update employee',
                  child: <UpdateUserForm data={row} onSuccess={onSuccess} />,
                });
              },
            },
            {
              icon: ICONS.delete,
              name: 'Delete Permanentaly',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to delete this Employee?',
                  onConfirm: () => onDelete(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });

  return (
    <>
      <Grid item xs={12}>
        <PageHeader pageTitle={'Employees'}>
          <MenuPopover
            isButton
            buttonText={'Add new user'}
            menuArray={[
              {
                name: 'Add email',
                icon: ICONS.email,
                action: () => {
                  setDialogData({
                    showModal: true,
                    title: 'Enter user email',
                    child: <AddUserForm onSuccess={onSuccess} company={id} />,
                  });
                },
              },
              {
                name: 'Upload file',
                icon: ICONS.upload,
                action: () => {
                  setDialogData({
                    showModal: true,
                    description: 'Upload File',
                    child: <ReadExcel onSuccess={onSuccess} company={id} />,
                  });
                },
              },
            ]}
          />
        </PageHeader>
      </Grid>
      <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
        <MenuPopover
          isButton
          buttonText={'Bulk Action'}
          menuArray={[
            {
              name: 'Add',
              icon: ICONS.add,
              action: () => {
                setDialogData({
                  showModal: true,
                  title: 'Bulk Add',
                  child: <EmployeeActionForm company={id} onSuccess={onSuccess} />,
                });
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          isServerSidePagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={updatedUserTable}
          rows={data.rows}
          rowCount={data.rowCount}
        />
      </Grid>
    </>
  );
};

export default EmployeeSection;
