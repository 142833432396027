import { useState } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { homeItemFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { showErrorTost, showSuccessTost } from 'src/constants';
import { UPDATE_HOME_PAGE } from 'src/apiService/apiDeclaration';
import RichText from 'src/components/formFields/richText';
import LoaderButton from 'src/components/loaderButton/loaderButton';

const HomeItemForm = ({ data, parent, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    head: { en: data.head.en, fr: data.head.fr },
    body: { en: data.body.en, fr: data.body.fr },
    footer: { en: data.footer.en, fr: data.footer.fr },
  };
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(homeItemFormSchema),
    defaultValues,
  });
  const {
    formState: { errors, isValid, isDirty },
    control,
    handleSubmit,
  } = methods;

  const onUpdate = async (body) => {
    setLoading(true);
    try {
      await UPDATE_HOME_PAGE(parent, { middle: { ...body, id: data.id } });
      onSuccess();
      showSuccessTost('Home page updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating home page data.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onUpdate)}>
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <RichText
              label={'Head (English)'}
              controller={{
                name: 'head.en',
                control: control,
                errors: errors?.head?.en,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              label={'Head (French)'}
              controller={{
                name: 'head.fr',
                control: control,
                errors: errors?.head?.fr,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              label={'Body (English)'}
              controller={{
                name: 'body.en',
                control: control,
                errors: errors?.body?.en,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              label={'Body (French)'}
              controller={{
                name: 'body.fr',
                control: control,
                errors: errors?.body?.fr,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LoaderButton disabled={!isValid || !isDirty} loading={loading} buttonText={'Update'} type="submit" />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default HomeItemForm;
