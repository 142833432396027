import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ICONS } from 'src/assets/library';
import { feedbackFormSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UPDATE_FEEDBACK } from 'src/apiService/apiDeclaration';
import CustomTextField from 'src/components/formFields/textField';
import LoaderButton from 'src/components/loaderButton/loaderButton';
import { showErrorTost, showSuccessTost } from 'src/constants';

const FeedbackDetailForm = ({ data, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    answer: data.answer,
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(feedbackFormSchema),
    defaultValues,
  });

  const onSubmit = async (body) => {
    setLoading(true);
    try {
      await UPDATE_FEEDBACK(data._id, body);
      showSuccessTost('Reply sent successfully.');
      onSuccess();
    } catch (error) {
      showErrorTost('Error in replying ');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <CustomTextField label={'Name'} icon={ICONS.user} value={data.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField label={'Email'} type="email" icon={ICONS.email} value={data.email} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField label={'Issue'} value={data.issue} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField label={'Description'} value={data.description} multiline minRows={4} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            disabled={data.answer}
            label={'Reply'}
            multiline
            minRows={4}
            controller={{
              name: 'answer',
              control: control,
              errors: errors.answer,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <LoaderButton
            disabled={data.answer || !isValid || !isDirty}
            loading={loading}
            buttonText={'Reply'}
            type={'submit'}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default FeedbackDetailForm;
