import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ICONS } from 'src/assets/library';
import { setingsSchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UPDATE_SETTINGS } from 'src/apiService/apiDeclaration';
import CustomTextField from 'src/components/formFields/textField';
import LoaderButton from 'src/components/loaderButton/loaderButton';
import { showErrorTost, showSuccessTost } from 'src/constants';
import RichText from '../formFields/richText';

const SettingsForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    tokens: data.tokens,
    price: data.price,
    rate: data.rate,
    perCreditPriceMonthly: data.perCreditPriceMonthly,
    perCreditPriceYearly: data.perCreditPriceYearly,
    stripeMonthlyCreditId: data.stripeMonthlyCreditId,
    stripeYearlyCreditId: data.stripeYearlyCreditId,
    standAloneStripeCreditDescription: data.standAloneStripeCreditDescription,
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(setingsSchema),
    defaultValues,
  });

  const onSubmit = async (body) => {
    setLoading(true);
    try {
      await UPDATE_SETTINGS(data._id, body);
      showSuccessTost('Settings updated successfully.');
    } catch (error) {
      showErrorTost('Error in updating settings.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <CustomTextField
            label={'Tokens'}
            type="number"
            icon={ICONS.words}
            controller={{
              name: 'tokens',
              control: control,
              errors: errors.tokens,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Price'}
            type="number"
            icon={ICONS.price}
            controller={{
              name: 'price',
              control: control,
              errors: errors.price,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={'Rate'}
            type="number"
            icon={ICONS.rate}
            controller={{
              name: 'rate',
              control: control,
              errors: errors.rate,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Per Credit Price Monthly'}
            type="number"
            icon={ICONS.price}
            controller={{
              name: 'perCreditPriceMonthly',
              control: control,
              errors: errors.perCreditPriceMonthly,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Per Credit Price Yearly'}
            type="number"
            icon={ICONS.price}
            controller={{
              name: 'perCreditPriceYearly',
              control: control,
              errors: errors.perCreditPriceYearly,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Monthlt Stripe Credit Id'}
            controller={{
              name: 'stripeMonthlyCreditId',
              control: control,
              errors: errors.stripeMonthlyCreditId,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Yearly Stripe Credit Id'}
            controller={{
              name: 'stripeYearlyCreditId',
              control: control,
              errors: errors.stripeYearlyCreditId,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Stand Alone Stripe Credit Description (English)'}
            controller={{
              name: 'standAloneStripeCreditDescription.en',
              control: control,
              errors: errors?.standAloneStripeCreditDescription?.en,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichText
            label={'Stand Alone Stripe Credit Description (French)'}
            controller={{
              name: 'standAloneStripeCreditDescription.fr',
              control: control,
              errors: errors?.standAloneStripeCreditDescription?.fr,
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <LoaderButton disabled={!isValid || !isDirty} loading={loading} buttonText={'Update'} type="submit" />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <LoaderButton onClick={() => reset(defaultValues)} buttonText={'reset'} color="warning" />
        </Grid>
      </Grid>
    </form>
  );
};

export default SettingsForm;
