import parse from 'html-react-parser';
import { Card, Grid, Typography } from '@mui/material';
import { GET_HOME_PAGE } from 'src/apiService/apiDeclaration';
import CustomLoader from 'src/components/loader/loader';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import useApiGetter from 'src/hooks/useApiGetter';
import { ICONS } from 'src/assets/library';
import { useModal } from 'src/hooks/useModal';
import HomeItemForm from 'src/components/form/home/homeItemForm';
import HomePageForm from 'src/components/form/home/homePageForm';

const AboutPage = () => {
  const { data, refresh } = useApiGetter(GET_HOME_PAGE);
  const { handleModalClose, setDialogData } = useModal();

  const onSuccess = () => {
    handleModalClose();
    refresh();
  };
  return (
    <Grid container spacing={2}>
      <TabTitle title={'Home'} />
      <Grid item xs={12}>
        <PageHeader pageTitle={'Home'} />
      </Grid>
      {!data ? (
        <Grid item xs={12}>
          <Card>
            <CustomLoader />
          </Card>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>{<HomePageForm data={data} />}</Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h6" mb={1}>
                Middle Section
              </Typography>
              {data.middle.map((item, index) => (
                <Grid display={'flex'} gap={1} key={index} py={0.5}>
                  <Typography>{parse(item.head.en)}</Typography>
                  <Grid
                    onClick={() =>
                      setDialogData({
                        showModal: true,
                        title: 'Update',
                        child: <HomeItemForm data={item} parent={data._id} onSuccess={onSuccess} />,
                      })
                    }
                    sx={{ cursor: 'pointer' }}
                  >
                    {ICONS.edit}
                  </Grid>
                </Grid>
              ))}
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AboutPage;
